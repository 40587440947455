import { defineStore } from 'pinia'

export const useAuthStore = defineStore({
  id: 'Auth',
  state: () => ({
    user: '',
    network: '',
    contract: '',
  }),
  getters: {
    isAuthenticated: state => state.user,
    accountInfo: state => ({
          user: state.user,
          network: state.network,
          contract: state.contract})
  },
  actions: {
    async bindUser() {

    }
  }
})
